/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


/*
 * Activation Swipe sur le bloc actu de la home en mode smartphone
 */
// function activeSwipe(){
//     if (jQuery(window).width() <= 992) {
//         window.mySwipe.setup();
//     }
//     else {
//        window.mySwipe.kill();
//     }
// }

/*
 * Animation Blocs
 */
// function animateBlocs(bloc,animation){
    
//      jQuery(bloc).addClass("hidden-animation").viewportChecker({
//         classToAdd: 'visible-animation animated '+animation, // Class to add to the elements when they are visible
//         offset: 100    
//        }); 
// }

/*
 * Scroll to view
 */
function isScrolledIntoView(elem)
{
    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + jQuery(window).height();

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();

    return ((elemTop <= docViewBottom) && (elemBottom >= docViewTop));
}

var inView = [];
function animeChart(i,canvas){
    
    inView[i] = false;
                    
    jQuery(window).scroll(function() {
        if (isScrolledIntoView('#'+canvas)) {
            if (inView[i]) { return; }
            inView[i] = true;
            
            var ctx = document.getElementById(canvas).getContext('2d');            
            var chart = jQuery('#'+canvas).data('chart');
            var mainColor = '#01808f';
            Chart.defaults.global.animation.duration = 3000;
            if(chart==="pie"){
                var valeurs = jQuery('#'+canvas).data('valeurs');
                var data_valeurs = valeurs.split(";");
                
                new Chart(ctx, {
                    type : 'pie',
                    data: {
                        datasets: [
                            {
                                data: data_valeurs,
                                backgroundColor: [
                                    "#dddedd",
                                    "#15818f"
                                ],
                                hoverBackgroundColor: [
                                    "#dddedd",
                                    "#15818f"
                                ]
                            }]
                    },
                    options: {
                        tooltips:{
                            enabled:false,
                        },
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        },
                        animation:{
                            animateRotate:true
                        },
                        legend: {
                            display:false
                        }
                    }
                });
            }
            
            else if(chart==="bar"){      
                
                var valeurs = jQuery('#'+canvas).data('valeurs');
                valeurs = "0;"+valeurs;
                var data_valeurs = valeurs.split(";");
                
                var labels = jQuery('#'+canvas).data('labels');
                labels = ";"+labels;
                var data_labels = labels.split(";");                
                new Chart(ctx, {
                    type : 'bar',
                    
                    data: {
                        labels: data_labels,
                        datasets: [{
                            borderColor: mainColor,
                            data: data_valeurs,
                            lineTension:0,
                            pointRadius:0,
                            backgroundColor: ["#ffffff","#dddddd", "#bacfd2", "#94c0c5", "#59a2ab","#388690","#1d6f79","#075059"],
                        }],
                    },
                     options: {
                            defaultFontFamily:"SegoeUIEmoji",
                            defaultFontColor:mainColor,
                            tooltips:{
                                enabled:false,
                            },
                            legend: {
                                display: false,
                            },
                            scales: {
                                yAxes: [{
                                    gridLines:{
                                        color:"transparent",
                                        zeroLineColor:mainColor,
                                    },
                                    ticks:{
                                        stepSize: 20000000,
                                        fontColor:mainColor,
                                        fontSize:20,
                                        fontFamily:"Montserrat",
                                        fontStyle:"bold",
                                        padding: 30,
                                        callback: function(label, index, labels) {
                                            return label/1000000;
                                        }
                                    }
                                }],
                                xAxes: [{
                                    barPercentage: 0.82,
                                    gridLines:{
                                        color:"transparent",
                                        zeroLineColor:mainColor,
                                        tickMarkLength: 30  
                                    },
                                    ticks:{
                                        fontColor:mainColor,
                                        fontSize:18,
                                        fontFamily:"Montserrat",
                                        fontStyle:"300",
                                    }
                                }]
                            }
                        }
                });
            }
            
            else if(chart==="line"){   
                
                var valeurs = jQuery('#'+canvas).data('valeurs');
                var data_valeurs = valeurs.split(";");
                
                var labels = jQuery('#'+canvas).data('labels');
                var data_labels = labels.split(";");
                
                new Chart(ctx, {
                    type: 'line',

                    data: {
                        labels: data_labels,
                        datasets: [{
                            backgroundColor: mainColor,
                            borderColor: mainColor,
                            data: data_valeurs,
                            lineTension:0,
                            pointRadius:0,
                        }]
                    },
                    options: {
                        defaultFontFamily:"SegoeUIEmoji",
                        defaultFontColor:mainColor,
                        legend: {
                            display: false,
                        },
                        scales: {
                            yAxes: [{
                                gridLines:{
                                    color:"transparent",
                                    zeroLineColor:mainColor,
                                },
                                ticks:{
                                    fontColor:mainColor,
                                    fontSize:20,
                                    fontFamily:"Montserrat",
                                    fontStyle:"bold",
                                    padding: 35,
                                    callback: function(label, index, labels) {
                                        if(label !== 0) return label+"%"; else return label;
                                    }
                                }
                            }],
                            xAxes: [{
                                gridLines:{
                                    color:"transparent",
                                    zeroLineColor:mainColor,
                                    tickMarkLength: 30  
                                },
                                ticks:{
                                    fontColor:mainColor,
                                    fontSize:18,
                                    fontFamily:"Montserrat",
                                    fontStyle:"300",
                                }
                            }]
                        }
                    }
                });
            }
            else if(chart==="multiline"){
                var a = jQuery('#'+canvas).data('valeursa');
                var data_a = a.split(";");
                var b = jQuery('#'+canvas).data('valeursb');
                var data_b = b.split(";");
                var c = jQuery('#'+canvas).data('valeursc');
                var data_c = c.split(";");
                var d = jQuery('#'+canvas).data('valeursd');
                var data_d = d.split(";");
                var total = jQuery('#'+canvas).data('total');
                var data_total = total.split(";");
                
                var labels = jQuery('#'+canvas).data('labels');
                var data_labels = labels.split(";");
                
                new Chart(ctx, {
                // The type of chart we want to create
                type: 'line',

                // The data for our dataset
                data: {
                    labels: data_labels,
                    datasets: [
                        {
                        backgroundColor:'transparent',
                        borderColor: '#dddddd',
                        data: data_a,
                        pointRadius:0,
                        lineTension:0,
                        },
                        {
                        backgroundColor:'transparent',
                        borderColor: '#94c0c5',
                        data: data_b,
                        pointRadius:0,  
                        lineTension:0,
                        },
                        {
                        backgroundColor:'transparent',
                        borderColor: '#388690',
                        data: data_c,
                        pointRadius:0,  
                        lineTension:0,
                        },
                        {
                        backgroundColor:'transparent',
                        borderColor: '#075059',
                        data: data_d,
                        pointRadius:0,   
                        lineTension:0,
                        },
                        {
                        backgroundColor:'transparent',
                        borderColor: '#f06d6d',
                        data: data_total,
                        pointRadius:0,  
                        lineTension:0,
                        }
                    ],
                },

                // Configuration options go here
                options: {
                    defaultFontFamily:"SegoeUIEmoji",
                    defaultFontColor:mainColor,
                    legend: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            gridLines:{
                                color:"transparent",
                                zeroLineColor:mainColor,
                            },
                            ticks:{
                                fontColor:mainColor,
                                fontSize:20,
                                fontFamily:"Montserrat",
                                fontStyle:"bold",
                                padding: 35,
                                callback: function(label, index, labels) {
                                    return label/1000000;
                                }
                            }
                        }],
                        xAxes: [{
                            gridLines:{
                                color:"transparent",
                                zeroLineColor:mainColor,
                                tickMarkLength: 30  
                            },
                            ticks:{
                                fontColor:mainColor,
                                fontSize:18,
                                fontFamily:"Montserrat",
                                fontStyle:"300",
                            }
                        }]
                    }
                }
            });
                
            }
            else if(chart==="progressbar"){
                var a = jQuery('#'+canvas).data('valeur');
                ctx.canvas.height = 16;
                new Chart(ctx, {
                    type: 'horizontalBar',

                    data: {
                            labels: [""],
                            datasets: [
                                {
                                    data: [a],
                                    backgroundColor: [mainColor],
                                    borderWidth:[0 ]
                                }]
                        },

                    options: {
                        tooltips:{
                            enabled:false,
                        },
                        legend: {
                            display: false,
                        },
                        scales: {
                            xAxes: [{
                                categoryPercentage:0,
                                display: false,
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    max: 100
                                }
                            }],
                            yAxes: [{
                                display: false
                            }]
                        }

                    }
                });
    
                
            }
            
        }
    });
    
}



(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {       
           //  // SWIPE
           //  var element = document.getElementById('swipe-actu');
           //  window.mySwipe = new Swipe(element, {
           //    startSlide: 0,
           //    continuous: true
           //  });        
           //  console.log(window.mySwipe);    
           //  activeSwipe();

           //  // SWIPE
           //  $( window ).resize(function() {
           //      activeSwipe();
           // });
            // Toggle Menu Smartphone
            $('#menu-toggle').click(function(){ 
                $('#menu-toggle').toggleClass('active');
                $('.nav-primary-mobile').toggleClass('active');
            });     
            
            // Toggle Menu Lang
            $('.link-change-lang').click(function(){ 
                $('.languages-block ul').toggleClass('active');
                
                return false;
            }); 
            
            // Click Player Video
            $('.link-show-video').click(function(){ 
                var idvideo = $(this).data('idvideo');
                var nomvideo = $(this).data('nomvideo');
                
                $.post(
                    ajaxurl,
                    {
                        'action': 'load_player_video',
                        'idvideo':idvideo
                    },
                    function(response){  
                        $('#modalPlayerVideo .modal-title').html(nomvideo);
                        $('#modalPlayerVideo .modal-body').html(response);

                        $('#modalPlayerVideo').modal('show');
                        
                        
                        $('#modalPlayerVideo').on('hidden.bs.modal', function () {
                            $('#modalPlayerVideo .modal-body').html("...");
                          });

                    }
                );
                
                return false;
            }); 
            

            // Menu Sticky
            $( window ).scroll(function() {            
                if($(window).scrollTop() <= '150'){
                    $('.header-menu').removeClass('header-menu-sticky');
                }
                else{
                    $('.header-menu').addClass('header-menu-sticky');
                }
            }); 

        
            // Scroll to videos
            $('.link-home-scroll').click(function(){                
                var t = $(".main-content").offset().top - 70;
                                
                $('html, body').animate({
                    scrollTop: t
                }, 1500);   
                
                 return false; 
            }); 


            if (!Modernizr.objectfit) {
              objectFitImages('img.object-fit');
              console.log("dqsdjlsq");
            }     
        
      },
      finalize: function() {

        
            // ANIMATION home 
            // animateBlocs('.wrapper-section-invest','fadeIn');
            // animateBlocs('.wrapper-section-actu','fadeIn');
            // animateBlocs('.wrapper-section-contact','fadeIn');

          
            // animateBlocs('.sec-home-right','fadeInRight');
            // animateBlocs('.sec-home-left','fadeInLeft');

      }
    },
    
    // HOME
    'home': {
      init: function() {            
        
     
      },
      finalize: function() {        
            
            
      }
    },
    
    // PAGES SYSTEM
    'page_template_template_system': {
      init: function() {
        
            // ANIMATION
            // animateBlocs('.content-system-right','fadeInRight');
            // animateBlocs('.content-system-left','fadeInLeft');
            
        
      }
    },
    
    // PAGE TECHNOLOGIE
    'page_template_template_techno': {
      init: function() {
        
            // ANIMATION            
            // animateBlocs('.wrapper-content-techno','fadeIn');
            // animateBlocs('.img-technologie-left','fadeInLeft');
            // animateBlocs('.img-technologie-right','fadeInRight');
        
      }
    },
    
    // PAGE IDNEST 
    'page_template_template_idnest': {
      init: function() {
        
            $('.link-onglet').click(function(){  
                var cat= $(this).data("cat");   
                
                $(this).addClass('active');
                $(".link-onglet:not(.link-onglet-"+cat+")").removeClass('active');
                
                if(cat==='all'){
                    $( ".row-equipe .col-equipe" ).show(); 
                }
                else {
                    $( ".row-equipe .col-equipe."+cat+"" ).show(); 
                    $( ".row-equipe .col-equipe:not(."+cat+")" ).hide(); 
                }
                return false; 
            });
            
            
            // ANIMATION
            // animateBlocs('.wrapper-content-idnest','fadeIn');            
        
      }
    },
    
    // PAGE INVESTISSEURS
    'page_template_template_investisseurs': {
        init: function() {
            
            $('canvas').each(function(i){
                var id = $(this).attr('id');
               /*
                var typechart = $(this).data('typechart');
                
                if(typechart=="pie"){
                    var test = "30;45";
                    var datachart = test.split(';');
                    
                    
                   // var datachart = [$(this).data('val1'),$(this).data('val2')];
                }
                
                
                animateChart(i,id,typechart,datachart);*/
                animeChart(i,id);
            });
            
        }
    },
    
    // ACTUALITES
    'page_template_template_actu': {
      init: function() {
        
            var posts_per_page= $( ".row-actu-mini" ).data("posts-per-page");
            var exclu= $( ".row-actu-mini" ).data("exclu");
        
            // Filtre catégories articles
            $('.link-onglet').click(function(){  
                
                // change filtre actif
                var cat= $(this).data("cat");                   
                $(this).addClass('active');
                $(".link-onglet:not(.link-onglet-"+cat+")").removeClass('active');                
                
                // affichage des articles de la catégorie sélectionnée
                var idcat = $('.row-onglets .link-onglet.active').data('idcat');
                var nb_total= $( ".row-onglets .link-onglet.active" ).data("nb-posts-cat");                
                var nb_posts = $( ".row-actu-mini article" ).length;

                $.post(
                    ajaxurl,
                    {
                        'action': 'load_more_articles',
                        'nb_total': nb_total,
                        'posts_per_page': posts_per_page,
                        'nb_posts': nb_posts,
                        'idcat': idcat,
                        'exclu': exclu,
                        'from':'filter'
                    },
                    function(response){                        
                        $('.row-actu-mini').html(response);
                        var new_nb = $( ".row-actu-mini article" ).length;
                        if(new_nb>=nb_total){
                            $('.row-link-load-articles').addClass('hidden');
                        }
                        else {                            
                            $('.row-link-load-articles').removeClass('hidden');
                        }
                    }
                );               
                return false;
            });
        
            // Charger + d'articles
            $('.link-load-articles').click(function(){
                
                var idcat = $('.row-onglets .link-onglet.active').data('idcat');
                var nb_total= $( ".row-onglets .link-onglet.active" ).data("nb-posts-cat");                
                var nb_posts = $( ".row-actu-mini article" ).length;
                
                $.post(
                    ajaxurl,
                    {
                        'action': 'load_more_articles',
                        'nb_total': nb_total,
                        'posts_per_page': posts_per_page,
                        'nb_posts': nb_posts,
                        'idcat': idcat,
                        'exclu': exclu,
                        'from':'more'
                    },
                    function(response){                        
                        $('.row-actu-mini').append(response);
                        var new_nb = $( ".row-actu-mini article" ).length;
                        if(new_nb>=nb_total){
                            $('.row-link-load-articles').addClass('hidden');
                        }
                        else {                            
                            $('.row-link-load-articles').removeClass('hidden');
                        }
                    }
                );
                return false;
            });
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.